
.global-app-wrapper {
    display: flex;
    flex-direction: column;
    flex: 100vh;
    min-height: 100vh;
}

.change-log-wrap {
    margin-top: auto;
}


